const menuicon = document.querySelector('.menuicon');
const nav = document.querySelector('.menuicon');
const navLinks = document.querySelectorAll('.nav-link');

function handleClick() {
  const isOpened = menuicon.getAttribute('aria-expanded');
  if (isOpened === 'false') {
    menuicon.setAttribute('aria-expanded', 'true');
    document.body.classList.add('nav-is-open');
    document.body.classList.remove('nav-is-closed');
  } else {
    menuicon.setAttribute('aria-expanded', 'false');
    document.body.classList.remove('nav-is-open');
    document.body.classList.add('nav-is-closed');
  }
}

menuicon.addEventListener('click', handleClick);

function handleScroll() {
  const isOpened = menuicon.getAttribute('aria-expanded');
  if (isOpened === 'true') {
    menuicon.setAttribute('aria-expanded', 'false');
    document.body.classList.remove('nav-is-open');
    document.body.classList.add('nav-is-closed');
  }
}

window.addEventListener('scroll', handleScroll);

navLinks.forEach(link => {
  link.addEventListener('click', () => {
    menuicon.setAttribute('aria-expanded', 'false');
    document.body.classList.remove('nav-is-open');
    document.body.classList.add('nav-is-closed');
  });
});